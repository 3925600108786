<template>
  <div class="pageBox">
    <div class="box">
      <div class="searchBox">
        <div class="sIcon"><img src="../../assets/img/ssIcon.png" alt=""></div>
        <div class="inp">
          <el-input v-model="searchValue" :placeholder="tType == 'CH'?'请输入内容':'Please enter the content'"></el-input>
        </div>
        <div class="clear el-icon-error" @click="clearBtn"></div>
        <div class="searchBtn" @click="searchList">
          <div class="btn" v-if="tType == 'CH'">搜索</div>
          <div class="btn" v-if="tType == 'EN'">search</div>
        </div>
      </div>

      <div class="searchLists">
        <div class="itemBox" v-for="(item,index) in list" :key="index" @click="go(item)">
          <div class="item">
            <div class="left">
              <div class="title">{{ item.title }}</div>
              <div class="time">{{ item.createtime }}</div>
            </div>
            <div class="right">
              {{ item.type }}
            </div>
          </div>
        </div>

      </div>

<!--      <div class="searchList">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="公告资讯" name="1" class="tab1">
            <div class="num">{{ total }}个搜索结果</div>
            <div class="tab1List">
              <div class="r_item" v-for="(item,index) in list" :key="item.id" @click="go(item.id)">
                <div class="img">
                  <img :src="item.cover" alt="">
                </div>
                <div class="content">
                  <div class="text_left">
                    <div class="text1">
                      <div class="tTitle">{{ item.title }}</div>
                      <div class="ziti">{{ item.publishTime }}</div>
                    </div>
                    <div class="text2"><div><span>#原创</span></div></div>
                    <div class="text3">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="住宿" name="2" class="tab1">
            <div class="num">{{ total }}个搜索结果</div>
            <div class="tab1List">
              <div class="r_item" v-for="(item,index) in list" :key="item.id" @click="go(item.id)">
                <div class="img">
                  <img :src="item.cover" alt="">
                </div>
                <div class="content">
                  <div class="text_left">
                    <div class="text1">
                      <div class="tTitle">{{ item.title }}</div>
                      <div class="ziti">{{ item.publishTime }}</div>
                    </div>
                    <div class="text2"><div><span>#原创</span></div></div>
                    <div class="text3">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>-->
    </div>
  </div>
</template>

<script>
import {getSearchList} from '@/api/index'
export default {
name: "searchPage",
  data(){
    return{
      searchValue:'',
      activeName:'1',
      list:[],
      total: 0,
      tType: 'CH',
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
    // console.log(this.$route.query.value)
    if(this.$route.query.value.trim() !=''){
      this.searchValue = this.$route.query.value
      this.searchList()
    }
  },
  methods:{
    searchList(){
      getSearchList({
        // type: Number(this.activeName),    // 类型 1.咨询公告 2.住宿 3.游船 4.其他
        // keyWord: this.searchValue,    // 搜索关键字
        title: this.searchValue,    // 搜索关键字
      }).then(res=>{
        // console.log(res)
        if(res.code==200){
          this.list = res.rows
          this.list.forEach((item,index)=>{
            item.createtime = item.createtime.substr(0,10)
          })
          this.total = res.total
        }
      })
    },
    go(item){
      this.$route.query.value = ''
      this.$router.push({path:'/searchPage/searchInfo',query:{...item}})
      // if(this.activeName == '1'){
      //   this.$router.push({path:'/information/informationInfo',query:{id}})
      // }else if(this.activeName == '2'){
      //   this.$router.push({path:'/ticketing/hotelHomestayInfo',query:{id}})
      // }
    },
    clearBtn(){
      this.$route.query.value = ''
      this.searchValue = '';
      this.total = 0;
      this.list = [];
    },
    handleClick(){
      if(this.searchValue){
        this.searchList()
      }

    }
  }
}
</script>

<style scoped lang="less">
  .pageBox{
    //width: 1300px;
    width: 100%;
    min-height: 800px;
    margin: auto;
    padding: 80px 0 150px;
  }
  .box{
    width: 1300px;
    margin: auto;
    .searchBox{
      width: 960px;
      height: 70px;
      margin: auto;
      background: #FFFFFF;
      border-radius: 35px;
      display: flex;
      align-items: center;
      .sIcon{
        width: 30px;
        height: 30px;
        margin: 0 30px 0 35px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .inp{
        width: 665px;
        .el-input {
          /deep/ input {
            border: none;
            font-size: 20px;
          }
        }
      }
      .clear{
        font-size: 30px;
        color: #AAAAAA;
        margin: 0 30px;
        cursor: pointer;
      }
      .searchBtn{
        width: 100px;
        height: 50px;
        background: #007B8A;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        .btn{
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .searchLists{
      padding: 50px 0;
      background: #FFFFFF;
      margin-top: 40px;
      .itemBox{
        padding: 0 50px;
        &:hover{
          background: #F2F8F9;
          .right{
            background: #007B8A;
            color: #FFFFFF;
          }
        }
      }
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #dddddd;
        .left{
          .title{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            //font-weight: 900;
            color: #333333;
          }
          .time{
            margin-top: 15px;
            font-size: 14px;
            font-family: STHeitiSC-Medium, STHeitiSC;
            font-weight: 500;
            color: #333333;
          }
        }
        .right{
          padding: 7px 19px;
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #000000;
          background: #EEEEEE;
        }
      }
    }

    .searchList{
      width: 100%;
      margin-top: 40px;
      padding: 50px;
      background: #FFFFFF;
      /deep/.el-tabs__header{
        .el-tabs__nav-wrap{
          &::after{
            width: 0;
          }
        }
        .el-tabs__nav-scroll{
          border-bottom: 1px solid #DDDDDD;
        }
        .el-tabs__nav{
          padding-bottom: 20px;
        }
        .el-tabs__active-bar{
          width: 30px !important;
          height: 4px;
          background: #007B8A;
          margin: 0px 0 15px 30px;
        }
        .el-tabs__item{
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #333333;
          width: 100px;
          padding: 0;
          text-align: center;
        }
        .is-active{
          font-size: 20px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
        }
      }

      .tab1{
        .num{
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #333333;
          margin: 20px 0 30px;
        }
        .tab1List{
            width: 1200px;
            margin: 0 auto;
            .r_item{
              display: flex;
              align-items: center;
              background: #FFFFFF;
              border-radius: 20px;
              overflow: hidden;
              margin-bottom: 30px;
              &:hover{
                cursor:pointer;
                .content .text_left .text1 .tTitle{
                  color: #337D8D;
                }
                //.img img{
                //transform: scale(1.1);
                //}
              }
              &:nth-last-child(1){
                margin-bottom: 0;
              }
              .img{
                width: 300px;
                height: 200px;
                margin-right: 40px;
                img{
                  width: 100%;
                  height: 100%;
                  //transition:all 500ms ease;
                  //transform: scale(1);
                }
              }
              .content{
                width: 820px;
                height: 160px;
                //display: flex;
                //justify-content: space-between;
                .text_left{
                  //width: 665px;
                  .text1{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .tTitle{
                      max-width: 80%;
                      font-size: 20px;
                      font-family: STSongti-SC-Black, STSongti-SC;
                      font-weight: 900;
                      color: #333333;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 1; /*截取第三行*/
                      overflow: hidden;
                    }
                    .ziti{
                      font-size: 14px;
                      font-family: STHeitiSC-Medium, STHeitiSC;
                      font-weight: 600;
                      color: #333333;
                    }
                  }
                  .text2{
                    font-size: 12px;
                    font-family: "JDZY";
                    font-weight: 400;
                    color: #007B8A;
                    line-height: 17px;
                    margin: 20px 0 8px;
                    display: inline-block;
                    &>div{
                      display: flex;
                      padding: 5px 0;
                      //background: rgba(0, 123, 138, 0.1);
                      border-radius: 14px;
                      img{
                        width: 15px;
                        height: 16px;
                      }
                      span{
                        //margin-left: 5px;
                      }
                    }
                  }
                  .text3{
                    font-size: 18px;
                    font-family: "JDZY";
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /*截取第三行*/
                    overflow: hidden;
                  }
                }
                .text_right{
                  width: 180px;
                  cursor: pointer;
                  text-align: right;
                  padding-right: 40px;
                  box-sizing: border-box;
                  .text1{
                    font-size: 14px;
                    font-family: "JDZY";
                    font-weight: 600;
                    color: #333333;
                    line-height: 33px;
                    margin: 0 0 45px 0;
                    span{
                      margin-right: 20px;
                      color: #999999;
                    }
                  }
                  .text2{
                    font-size: 14px;
                    font-family: "JDZY";
                    font-weight: 400;
                    color: #337D8D;
                    line-height: 22px;
                    text-align: right;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    img{
                      width: 30px;
                      height: 30px;
                      margin-left: 20px;
                    }
                  }
                }
              }
            }
        }
      }

    }
  }
</style>
